import _path from "path";
import _resolveFrom from "resolve-from";
import _parentModule from "parent-module";
var exports = {};

function _nullRequire(id) {
  var e = new Error("Cannot find module '" + id + "'");
  e.code = "MODULE_NOT_FOUND";
  throw e;
}

const path = _path;
const resolveFrom = _resolveFrom;
const parentModule = _parentModule;

exports = moduleId => {
  if (typeof moduleId !== "string") {
    throw new TypeError("Expected a string");
  }

  const parentPath = parentModule(new URL(import.meta.url).pathname);
  const cwd = parentPath ? path.dirname(parentPath) : new URL(import.meta.url.slice(0, import.meta.url.lastIndexOf("/"))).pathname;
  const filePath = resolveFrom(cwd, moduleId);
  const oldModule = {}[filePath]; // Delete itself from module parent

  if (oldModule && oldModule.parent) {
    let i = oldModule.parent.children.length;

    while (i--) {
      if (oldModule.parent.children[i].id === filePath) {
        oldModule.parent.children.splice(i, 1);
      }
    }
  }

  delete {}[filePath]; // Delete module from cache

  const parent = {}[parentPath]; // If `filePath` and `parentPath` are the same, cache will already be deleted so we won't get a memory leak in next step

  return parent === undefined ? _nullRequire(filePath) : parent.require(filePath); // In case cache doesn't have parent, fall back to normal require
};

export default exports;